import axios from "@/api/baseRequest.js";

//增加课程发布平台
export const apiAddCoursePlatform = (token, formData) =>
    axios.post("/api/1/admin/coursePlatform/addCoursePlatform", formData, {
        headers: {
            token: token
        }
    });

//删除课程的发布平台
export const apiDeleteCoursePlatformById = (formData) => axios.post("/api/1/admin/coursePlatform/deleteCoursePlatformById", formData);
