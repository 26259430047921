<style scoped>
a {
    cursor: pointer;
}
</style>

<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            课程管理
            <span>&gt;</span>
            设置课程《{{ course.title }}》的发布平台
            <button class="veadmin-iframe-button" onclick="history.back();">
                <i class="el-icon-back"></i>
            </button>
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 20px">
                <el-steps :active="2" finish-status="success" align-center style="margin-bottom: 10px">
                    <el-step title="设置课程基本信息" description="已完成"></el-step>
                    <el-step title="设置课程标签" description="已完成"></el-step>
                    <el-step title="设置发布平台" description="进行中..."></el-step>
                    <el-step title="设置推广"></el-step>
                </el-steps>
                <div style="padding: 10px 0px">
                    <el-button type="primary" icon="el-icon-plus" @click="showAddCoursePlatform()">新增发布平台</el-button>
                    <el-button @click="jumpTo('/admin/course/setCourseAd?courseId=' + course.id + '&courseTitle=' + course.title)">下一步</el-button>
                </div>

                <el-card class="box-card" ref="addCoursePlatform" style="display: none">
                    <div slot="header" class="clearfix">
                        <span>新增发布平台</span>
                    </div>
                    <div class="text item">
                        <el-form ref="elForm" :model="form" status-icon :rules="rules" label-width="100px" style="margin: 20px">
                            <el-form-item label="请选择平台" prop="platformName">
                                <el-select v-model="form.platform_name" placeholder="请选择平台" @change="changeValue($event)">
                                    <el-option v-for="platform in platformList" :key="platform.name" :label="platform.name" :value="platform.name">
                                    </el-option>
                                </el-select>
                                <img ref="platformImg" style="margin-left: 10px; height: 40px; vertical-align: middle; display: none" />
                            </el-form-item>
                            <el-form-item label="链接" prop="url">
                                <el-input v-model="form.url" placeholder="请填写链接"></el-input>
                            </el-form-item>
                            <el-button type="primary" style="margin-left: 100px; width: 200px" @click="saveCoursePlatform()">保存</el-button>
                        </el-form>
                    </div>
                </el-card>

                <el-card class="box-card" style="margin: 10px 0px" v-for="coursePlatform in coursePlatformList" :key="coursePlatform.id">
                    <div slot="header" class="clearfix">
                        <span>发布的平台</span>
                        <el-button
                            style="float: right; margin-top: -10px"
                            type="danger"
                            icon="el-icon-delete"
                            plain
                            @click="deleteCoursePlatform(coursePlatform.id)"
                            >删除</el-button
                        >
                    </div>
                    <div class="text item">
                        <el-descriptions class="margin-top" :column="3">
                            <el-descriptions-item label="发布平台">
                                {{ coursePlatform.platform_name }}
                            </el-descriptions-item>
                            <el-descriptions-item label="链接">
                                <a @click="openNewWindow(coursePlatform.url)">{{ coursePlatform.url }}</a>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { apiListCoursePlatformByCourseId } from "@/api/public/coursePlatform.js";
import { apiAddCoursePlatform, apiDeleteCoursePlatformById } from "@/api/admin/coursePlatform.js";

export default {
    data() {
        var checkEmpty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("不能为空"));
            } else {
                callback();
            }
        };
        return {
            course: {
                id: this.$route.query.courseId,
                title: this.$route.query.courseTitle
            },
            platformList: [
                {
                    name: "51CTO学堂",
                    imgSrc: "/pictures/platform/edu.51cto.logo.png"
                }
            ],
            form: {
                platform_name: "",
                url: ""
            },
            rules: {
                platformName: [{ validator: checkEmpty, trigger: "blur" }],
                url: [{ validator: checkEmpty, trigger: "blur" }]
            },
            coursePlatformList: []
        };
    },
    methods: {
        changeValue(value) {
            //使用find()方法，根据value查找列表中的对象。
            let platform = this.platformList.find(function (item) {
                return item.name === value;
            });
            this.$refs.platformImg.src = platform.imgSrc;
            this.$refs.platformImg.style.display = "unset";
        },
        async listCoursePlatformByCourseId() {
            try {
                const response = await apiListCoursePlatformByCourseId(this.course.id);
                if (response.data.code == 200) {
                    this.coursePlatformList = response.data.data;
                    if (this.coursePlatformList.length > 0) {
                        this.$refs.addCoursePlatform.$el.style.display = "none";
                    } else {
                        this.$refs.addCoursePlatform.$el.style.display = "block";
                    }
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
                this.$message.error(error);
            }
        },
        saveCoursePlatform() {
            //去除首尾空格
            this.form.platform_name = this.form.platform_name.trim();
            this.form.url = this.form.url.trim();

            var formData = new FormData();
            formData.append("course_id", this.course.id);
            formData.append("platform_name", this.form.platform_name);
            formData.append("url", this.form.url);

            //调用接口 新增课程发布平台
            apiAddCoursePlatform(this.$store.state.token, formData).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: "保存成功！",
                        type: "success"
                    });
                    //执行成功1秒后刷新页面
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                } else {
                    console.log("response>>", response);
                    this.$message.error(response.data.message);
                }
            });
        },
        showAddCoursePlatform() {
            if (this.$refs.addCoursePlatform.$el.style.display == "none") {
                this.$refs.addCoursePlatform.$el.style.display = "block";
            } else {
                this.$refs.addCoursePlatform.$el.style.display = "none";
            }
            // this.$refs.addCoursePlatform.$el.style.display = "none";
        },
        async deleteCoursePlatform(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteCoursePlatformById(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        jumpTo(url) {
            window.location.href = url;
        },
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {
        this.listCoursePlatformByCourseId();
    }
};
</script>
