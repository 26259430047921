import axios from "@/api/baseRequest.js";

//根据courseId列出课程发布平台
export const apiListCoursePlatformByCourseId = function (courseId) {
    return axios.get("/api/1/pub/coursePlatform/listCoursePlatformByCourseId", {
        params: {
            courseId: courseId
        }
    });
};
